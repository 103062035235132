@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Bold.eot');
    src: url('../fonts/gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-Bold.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-Bold.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-SemiBold.eot');
    src: url('../fonts/gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-SemiBold.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-SemiBold.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Heavy.eot');
    src: url('../fonts/gilroy/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-Heavy.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-Heavy.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-Heavy.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-Heavy.svg#Gilroy-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Regular.eot');
    src: url('../fonts/gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-Regular.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-Regular.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Light.eot');
    src: url('../fonts/gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-Light.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-Light.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-Light.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-Light.svg#Gilroy-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-MediumItalic.eot');
    src: url('../fonts/gilroy/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-MediumItalic.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-MediumItalic.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-MediumItalic.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-MediumItalic.svg#Gilroy-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/gilroy/Gilroy-Medium.eot');
    src: url('../fonts/gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('../fonts/gilroy/Gilroy-Medium.woff') format('woff'),
        url('../fonts/gilroy/Gilroy-Medium.ttf') format('truetype'),
        url('../fonts/gilroy/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

