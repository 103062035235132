//Colors
$white:                    #fff;
$twilightgray:             #2d3436;
$lightred:                 #ff7675;
$red:                      #d63031;
$lightamber:               #ffeaa7;
$lightgrayazure:           #dfe6e9;
$grayshadeofblack:         #060606;
$lightredtwo:              #ff4f33;
$darkbluishgreen:          #00b894;
$darkbluishgreentwo:       #00cec9;

$Gilroy:                  'Gilroy';
