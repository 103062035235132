.wrapper{
	position: relative;
	overflow-x: hidden;
    font-family: $Gilroy;
}

.title{
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
	color: $twilightgray;
	font-size: 35px;
	letter-spacing: 0.39px;
	text-transform: uppercase;
	font-weight: 700;
	@media (max-width: 991px) {
		font-size: 25px;
	}
}


.page{
	padding-top: 65px;
	background: url(../img/pageFon.png) no-repeat top;
	background-size: cover;
	padding-bottom: 120px;
	@media (max-width: 991px) {
		padding-top: 50px;
	}
	.caption{
		text-align: center;
	}
}

.caption{
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
	color: $twilightgray;
	font-size: 38px;
	font-weight: 700;
}