.tab{
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding-top: 44px;
	max-width: 640px;
	margin: 0 auto;
	@media (max-width: 991px) {
		padding-top: 30px;
	}
	&__item{
		cursor: pointer;
		padding: 12px 18px 13px 18px;
		border-radius: 5px 5px 0 0;
		color: $white;
		font-size: 18px;
		font-weight: 500;
		background-color: $lightred;
		margin-right: 3px;
		width: 100%;
		&.active{
			cursor: default;
			background-color: $lightredtwo;
			padding: 18px 18px 20px 18px;
			@media (max-width: 991px) {
				padding: 5px;
			}
		}
		&:last-child{
			margin-right: 0;
		}
		@media (max-width: 1200px) {
			padding: 12px 12px 12px 12px;
		}
		@media (max-width: 991px) {
			padding: 5px;
		}
	}
	&__pane{
		max-width: 640px;
	    display: none;
	    margin-top: 5px;
	    margin: 0 auto;
	    padding: 36px 36px 32px 51px;
		font-size: 16px;
		font-weight: 400;
		@media (max-width: 991px) {
			padding: 15px 15px 15px 30px;
		}
	    &.active{
	    	background-color: $twilightgray;
		    display: block;	
		    color: $white;
		    text-align: left;
		}
	}
}