@import 'details/preloader';
@import 'details/libs';
@import 'variables/variables';
@import 'variables/template';
// @import 'details/preloader';

@import 'details/fonts';
@import 'details/forms';

@import 'details/scrollbar';
@import 'details/tab';

@import 'block/header';
@import 'block/menu';
@import 'block/contacts';

@import 'pages/main';
@import 'pages/living';


*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body{
	&.no-scroll {
		overflow: hidden;
		&:before{			
			content: '';
			transition: all .5s;
			z-index: 5;
			display: block;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .8);
		}
	}
}

.container{
	@media (min-width: 1800px) {
		max-width: 1640px;
	}
}