.header{
	padding-top: 49px;
	padding-bottom: 39px;
	background-color: $red;
	// position: absolute;
	// width: 100%;
	&Page{
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.19);
	}
	@media (max-width: 991px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	&__logo{
		img{
			max-width: 100%;
		}
	}
	&__menu{
		&.open{
			z-index: 6;
			background-color: $white;
			position: fixed;
			top: 0;
			left: 0;
			width: 250px;
			height: 100%;
			padding: 25px;
			overflow-x: auto;
			@media (max-width: 991px) {
				background-color: $red;
			}
			.menu{
				display: block;
				&__btn{
					position: fixed;
					right: 15px;
					top: 15px;
					span{
						background: $white;
						&:nth-child(1){
							transform: rotate(40deg);
						}
						&:nth-child(2){
							transform: rotate(-40deg);
							margin-top: -6px;
						}
						&:nth-child(3){
							display: none;
						}
					}
				}
			}
		}
		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}
}