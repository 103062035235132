.living{
	&__text{
		color: $twilightgray;
		font-size: 18px;
		font-weight: 300;
		letter-spacing: 0.2px;
		text-align: left;
		background: $white;
		padding: 30px;
		margin-bottom: 48px;
		margin-top: 33px;
		@media (max-width: 991px) {
			margin-bottom: 30px;
			margin-top: 30px;
		}
		a{
			color: $lightredtwo;
			border-bottom: 1px solid $lightredtwo;
			transition: all .5s;
			&:hover{
				text-decoration: none;
				opacity: 0.6;
				color: $lightredtwo;
			}
		}
		strong{
			font-weight: 700;
		}
	}
	&__plate{
		display: flex;
		flex-wrap: wrap;
		margin-right: -85px;
		overflow: hidden;
		@media (max-width: 991px) {
			margin-right: -30px;
		}

	}
	&__box{
		width: 33.3%;
		padding-bottom: 95%;
		padding-right: 85px;
		padding-bottom: 69px;
		text-align: center;
		@media (max-width: 991px) {
			padding-right: 30px;
			padding-bottom: 30px;
		}
		@media (max-width: 767px) {
			width: 100%;
			&:last-child{
				padding-bottom: 0;
			}
		}
		@media (min-width: 768px) {
			&:nth-last-child(-n+3){
				padding-bottom: 0;
			}
		}
	}
	&__photo{
		display: block;
		transition: all .5s;
		.living__img:after{
			content: '';
			display: block;
			position: absolute;
			background: url(../img/photoIcon.png) no-repeat center;
			background-size: contain;
			height: 30%;
			width: 30%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: all .5s;
		}
		&:hover{
			text-decoration: none;
			.living__img:after{
				background: url(../img/photoIconActive.png) no-repeat center;
				background-size: contain;
			}
		}
	}
	&__video{
		display: block;
		transition: all .5s;
		.living__img:after{
			content: '';
			display: block;
			position: absolute;
			background: url(../img/videoIcon.png) no-repeat center;
			background-size: contain;
			height: 30%;
			width: 30%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: all .5s;
		}
		&:hover{
			text-decoration: none;
			.living__img:after{
				background: url(../img/videoIconActive.png) no-repeat center;
				background-size: contain;
			}
		}
	}
	&__img{
		height: 100%;
		padding-bottom: 55%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: relative;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		border-radius: 5px;
	}
	&__plague{
		display: block;
	}
	&__date{
		color: $lightredtwo;
		font-size: 14px;
		font-weight: 700;
		padding-top: 10px;
	}
	&__name{
		color: $twilightgray;
		font-size: 18px;
		font-weight: 700;
		line-height: 18px;
	}
}