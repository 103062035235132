.alpha{
	padding-top: 0;
	padding-bottom: 130px;
	background-color: $red;
	&__span{
		text-shadow: 1px 1px 1px rgba(255, 79, 0, 0.16);
		color: $white;
		font-size: 110px;
		font-weight: 700;
		letter-spacing: 2.53px;
		line-height: 98px;
		@media (max-width: 1200px) {
	    	font-size: 80px;
	    }
		@media (max-width: 991px) {
			font-size: 70px;
			line-height: 72px;
			text-align: center;
		}
	}
	&__title{
		text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.16);
		color: $lightamber;
		font-size: 38px;
		letter-spacing: 1.33px;
		font-weight: 500;
		line-height: 44px;
		@media (max-width: 991px) {
			font-size: 24px;
			line-height: 34px;
			text-align: center;
		}
		span{
			display: block;
			text-shadow: 1px 1px 1px rgba(100, 53, 0, 0.16);
			color: $white;
			font-size: 26px;
			font-weight: 500;
			letter-spacing: 0.62px;
			@media (max-width: 991px) {
				font-size: 16px;
				text-align: center;
			}
		}
	}
	&__pressbutton{
		padding-top: 29px;
		max-width: 430px;
		display: flex;
		justify-content: space-between;
		@media (max-width: 991px) {
			padding-top: 15px;
			margin-bottom: 30px;
			margin: 0 auto;
			justify-content: center;
		}
		@media (max-width: 440px) {
			flex-wrap: wrap;
			text-align: center;
		}
	}
	&__request{
		margin-right: 19px;
		@media (max-width: 440px) {
			margin-bottom: 15px;
			width: 100%;
			margin-right: 0;
		}
		button{
			padding: 13px 24px 12px 24px;
			background-color: $twilightgray;
			border-radius: 5px;
			border: none;
			color: $white;
			font-weight: 700;
			letter-spacing: 0.2px;
			transition: all .5s;
			&::focus {
				outline: none;
			}	
			&:hover{
				opacity: 0.8;
			}
			@media (max-width: 1200px) {
				padding: 13px 14px 12px 14px;
				letter-spacing: -0.2px;
			}
		}
	}
	&__detail{
		button{
			padding: 13px 24px 12px 24px;
			background-color: $lightgrayazure;
			border-radius: 5px;
			border: none;
			color: $grayshadeofblack;
			font-weight: 700;
			letter-spacing: 0.2px;
			transition: all .5s;
			&::focus {
				outline: none;
			}
			&:hover{
				opacity: 0.8;
			}	
			@media (max-width: 1200px) {
				padding: 13px 14px 12px 14px;
				letter-spacing: -0.2px;
			}
		}
	}
	&__slider{
		background: url(../img/main/tray.png) no-repeat bottom;
		background-size: contain;
		display: flex;
		justify-content: center;
		padding-bottom: 12%;
		.slick-dots{
			bottom: 14%;
			right: 25px;
			@media (max-width: 767px) {
		    	right: 0;
		    }
		    button{
		    	display: none;
		    }
			li{
				width: 14px;
				height: 14px;
				background: url(../img/dots.png) no-repeat bottom;
				background-size: contain;
				border-radius: 50%;
				&:before{
					display: none;
				}
			}
			li.slick-active{
				width: 24px;;
				height: 22px;
				background: url(../img/dotsActive.png) no-repeat bottom;
				background-size: contain;
			}
		}
	}
	&__arrows{
		position: absolute;
		bottom: 7%;
		right: 25px;
		display: flex;
	    justify-content: center;
	    width: 100%;
	    @media (max-width: 767px) {
	    	display: none;
	    }
	}
	&__right{
		min-width: 16px;
		min-height: 30px;
		background: url(../img/right.png) no-repeat top;
		background-size: contain;
		margin: 0px 20px 10px 20px;
		cursor: pointer;
	}
	&__left{
		min-width: 16px;
		min-height: 30px;
		background: url(../img/left.png) no-repeat top;
		background-size: contain;
		margin: 0px 20px 10px 20px;
		cursor: pointer;
	}
	&__img{
		margin-right: 50px;
		outline: none;
		@media (max-width: 767px) {
	    	margin-right: 0;
	    }
	}
}

.showing{
	text-align: center;
	background: url(../img/main/showingFon.png) no-repeat top;
	background-size: cover;
	margin-top: -100px;
	padding-top: 215px;
	padding-bottom: 260px;
	@media (max-width: 991px) {
		padding-top: 150px;
		margin-top: -150px;
	}
	&__block{
		padding-top: 94px;
		@media (max-width: 991px) {
			padding-top: 30px;
		}
	}
	&__sample{
		color: $red;
		font-size: 30px;
		font-weight: 700;
		letter-spacing: 0.33px;
	}
	&__season{
		color: $darkbluishgreen;
		font-size: 30px;
		font-weight: 700;
		letter-spacing: 0.33px;
		@media (max-width: 991px) {
			padding-top: 30px;
		}
	}
}

.way{
	background: url(../img/main/wayFon.png) no-repeat top;
	background-size: cover;
	text-align: center;
	margin-top: -90px;
	padding-top: 190px;
	padding-bottom: 100px;
	@media (max-width: 991px) {
		padding-bottom: 50px;
		margin-top: -250px;
	}
	&__case{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding-bottom: 49px;
		padding-top: 41px;
		@media (max-width: 991px) {
			justify-content: flex-start;
			padding-bottom: 25px;
			padding-top: 25px;
			display: block;
		}
	}
	&__map{
		@media (max-width: 991px) {
			margin-bottom: 30px;
		}
	}
	&__point{
		color: $twilightgray;
		padding-bottom: 35px;
		text-align: left;
		@media (max-width: 991px) {
			padding-bottom: 15px;
		}
		&:last-child{
			padding-bottom: 0;
		}
	}
	&__title{
		font-size: 25px;
		font-weight: 700;
		@media (max-width: 991px) {
			font-size: 20px;
		}
	}
	&__text{
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.18px;
		margin: 10px 0;
		height: 48px;
		overflow: hidden;
		transition: all .5s;
		&-open{
			height: auto;
		}
		@media (max-width: 991px) {
			padding: 5px 0;
		}
	}
	&__link{
		color: $lightred;
		text-decoration: underline;
		transition: all .5s;
		cursor: pointer;
		display: inline-block;
		
		&:hover{
			text-decoration: none;
			color: $lightred;
		}
	}
	&__stamp{
		padding-top: 80px;
		text-align: center;
		@media (max-width: 991px) {
			padding-top: 30px;
		}
		span{
			background: url(../img/printer.png) no-repeat center;
			background-size: contain;
			min-width: 20px;
			min-height: 21px;
			display: inline-block;
			margin-right: 5px;
		}
		button{
			padding: 20px;
			background-color: $darkbluishgreentwo;
			border: none;
			color: $white;
			display: flex;
			align-items: center;
			margin: 0 auto;
			&::focus {
				outline: none;
			}			
		}
	}
}