.menu{
	list-style-type: none;
	display: flex;
	margin-bottom: 0;
	justify-content: center;
	@media (max-width: 130с0px) {
		flex-wrap: wrap;
	}
	@media (max-width: 991px) {
		display: none;
	}
	li{
		margin: 5px 1.5% 5px 1.5%;
		&:last-child{
			padding-right: 0;
		}
		@media (max-width: 991px) {
			display: block;
			width: 100%;
			padding: 5px 0 5px 0;
		}
		&.current a{
			border-radius: 5px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.06);
			background-color: $lightamber;
			border-bottom: none;
			color: $lightredtwo;
			text-decoration: none;
		}
	}
	a{
		color: $white;
		transition: all .5s;
		font-weight: 500;
		font-size: 18px;
		text-decoration: underline;
		padding: 5px 10px;
		white-space: nowrap;
		@media (max-width: 991px) {
			display: block;
			white-space: normal;
		}
		&:hover{
			color: $white;
		}
	}
	&__btn{
		display: none;
		z-index: 6;
		cursor: pointer;
		span{
			margin: 4px 0 4px auto;
			display: block;
			height: 2px;
			width: 20px;
			background: $white;
		}
		@media (max-width: 991px) {
			display: inline-block;
		    float: right;
		}
	}
}