.contacts{
	margin-top: -80px;
	background: url(../img/contactsFon.png) no-repeat top;
	background-size: cover;
	padding-bottom: 137px;
	padding-top: 181px;
	@media (max-width: 991px) {
		padding-bottom: 30px;
	}
	&__item{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 19px;
		font-size: 25px;
		font-weight: 700;
		letter-spacing: 0.28px;
		@media (max-width: 991px) {
			padding: 5px 0;
			justify-content: flex-start;
		}
	}
	&__link{
		color: $twilightgray;
		font-size: 25px;
		&:hover{
			text-decoration: none;
			color: $twilightgray;
		}
		@media (max-width: 991px) {
			font-size: 15px;
		}
	}
	&__block{
		padding-top: 24px;
		.contacts__item{
			justify-content: flex-start;
			padding: 0;
			padding-bottom: 10px;
		}
	}
	&__text{
		color: $twilightgray;
		font-size: 25px;
		font-weight: 300;
		letter-spacing: 0.28px;
		@media (max-width: 991px) {
			padding-top: 20px;
		}
	}
}

.phone{
	background: url(../img/phone.png) no-repeat center;
	background-size: contain;
	min-width: 31px;
	min-height: 31px;
	margin-right: 7px;
}

.address{
	background: url(../img/address.png) no-repeat center;
	background-size: contain;
	min-width: 31px;
	min-height: 31px;
	margin-right: 7px;
}